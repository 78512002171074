.title {

    text-align: center;
    margin-top: 130px;
    font: weight 100;
    font-size: 2.2rem;
    font-family: 'Open Sans', sans-serif;
}

.textarea {
    text-align: center;
    font-size: 1.5rem;
    height: 100%;
    width: 50%;
    margin: auto;
    margin-bottom: 1.5rem;
    font-family: 'Open Sans', sans-serif;
}


.textareaForm {
    display: flex;
    flex-direction: column;
    height: 100px;
    width: 250px
}

.inputField {
    widows: 180px;
}

.textareaButton{
    height: 35px;

}

.imageDiv {
    display: flex;
    flex-direction: row;
    height: 50px;
    width: 100%;
    justify-content: center;
}

.photo {
    height: 50px;
    width: 50px;
    background-size: contain;
    background-repeat: no-repeat; 
    background-position: center;
    z-index: 0;
    border-radius: 8px;
}

.dagaDiv {
    display: flex;
    width: 100%;
    height: 400px;
    margin-top: 30px;
}

.photoDaga {
    height: 400px;
    width: 400px;
    background-size: contain;
    background-repeat: no-repeat; 
    background-position: center;
    border-radius: 8px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
}

@media (max-width:415px){
    .textarea {
        text-align: center;
        font-size: 1.5rem;
        height: 100%;
        width: 90%;
        margin: auto;
        margin-bottom: 1.5rem;
    }
}