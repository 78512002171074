.DrawerToggle {
    width: 35px;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    align-items: center;
    padding: 10px 0;
    box-sizing: border-box;
    cursor: pointer;
}

.DrawerToggle div {
    width: 90%;
    height: 1px;
    background-color: black;
}

@media (min-width: 500px) {
    /* .DrawerToggle div {
        width: 90%;
        height: 2px;
        background-color: black;
    } */
}

.line {
    height: 2px;
}