.signin {
    margin-top: 100px;
    text-align: center;
    font-size: 1.2rem;
}

.form {
    text-align: center;
}

.signinButton {
    width:17rem;
    height: 2rem;
}

.formField {
    width:17rem;
    height: 2rem;
    font-size: 1.1rem;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
}

.formDiv {
    display: flex;
    width:100%
}