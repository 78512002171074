.title {
    margin-top: 7rem;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
}

.paragraph {
    font-size: 1.5rem;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
}

.pusher {
    height: 400px;
    width:200px;
    color:transparent
}

.succDiv {
    display: flex;
    flex-direction: column;
    text-align: center;
}


@media (max-width: 414px)  {
    .title {
        margin-top: 7rem;
    }
    
    .paragraph {
        width: 96%;
        margin-left: 2%;
        font-size: 1.2rem;
    }

    .pusher {
        height: 100px;
    }
 }