.bookingsGrid {
    display: grid;
    width: 21rem;
    grid-template-columns: repeat(3, 7rem);
    margin-top: 40px ;
    margin-left: auto;
    margin-right: auto;
}

.bookedTimesGrid {
    display: grid;
    width: 24rem;
    min-height: 20vh;
    grid-template-columns: repeat(1, 24rem);
    margin-top: 1rem ;
    margin-left: auto;
    margin-right: auto;
}

.pusherDiv {
    width: 200px;
    min-height: 30vh;
}

.booking {
    font-size: 1.2rem;
    place-self: center;
    text-align: center;
    height: 11rem;
    border-bottom: 1px black solid;
}

.booking div {
    margin:  5px 0 2px 0;
}

.title {
    margin-top:80px;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
}

.titleDate {
    margin-top:20px;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
}

.deleteConfirmation {
    position: fixed;
    text-align: center;
    top: 12%;
    left: 35%;
    width: 30%;
    height: 150px;
    border: 1px solid black;
    z-index: 99;
    background-color: white;
    border-radius: 5px;
}
.deleteParagraph {
    margin-top: 30px;
    margin-bottom: 15px;
    font-size: 1.4rem;
}

.deleteButton {
    margin-left: 25%;
    width:50%;
    height: 30px;
}

.confirmationButton {
    width:30%;
    height:30px;
}

@media (max-width: 414px) {
    .deleteConfirmation { 
        width:90%;
        margin-left: 5%;
        left: 0;
     }
  }